import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

function Qrscanner() {
  const iOSAppURL = "https://apps.apple.com/in/app/next-me/id6504331008"; // Replace with your App Store link
  const androidAppURL =
    "https://play.google.com/store/apps/details?id=com.nextmeapp&pli=1"; // Replace with your Play Store link

  // QR code URLs for iOS and Android
  const qrCodeURLiOS = `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${encodeURIComponent(
    iOSAppURL
  )}`;
  const qrCodeURLAndroid = `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${encodeURIComponent(
    androidAppURL
  )}`;

  return (
    <Container className="text-center py-5">
      <Row className="justify-content-center mb-4">
        <Col xs={12}>
          <h2>Download Our App</h2>
          <p>Scan the appropriate QR code based on your device:</p>
        </Col>
      </Row>
      <Row className="justify-content-center align-items-center">
        {/* iOS QR Scanner */}
        <Col xs={12} md={3} className="mb-3 mb-md-0">
          <a href={iOSAppURL} target="_blank" rel="noopener noreferrer">
            <img
              src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
              alt="Download on the App Store"
              style={{ height: "55px" }}
            />
          </a>
          <div className="text-center mt-3">
            <img
              src={qrCodeURLiOS}
              alt="iOS QR Code"
              className="mb-3"
              style={{ width: "150px", height: "150px" }}
            />
            <p>Scan this QR code to get it on the App Store (iOS)</p>
          </div>
        </Col>

        {/* Android QR Scanner */}
        <Col xs={12} md={3} className="mb-3 mb-md-0">
          <a
            href={androidAppURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
              alt="Get it on Google Play"
              style={{ height: "50px" }}
            />
          </a>
          <div className="text-center mt-3">
            <img
              src={qrCodeURLAndroid}
              alt="Android QR Code"
              className="mb-3"
              style={{ width: "150px", height: "150px" }}
            />
            <p>Scan this QR code to get it on Google Play (Android)</p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Qrscanner;
